var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-table',{ref:"table",staticClass:"table",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"header-cell-style":{
      background:'#f7f7f7',
      fontSize: '14px',
      fontFamily:'PingFang SC-Regular',
      fontWeight: 400,
      color: '#333333',
    }}},[_c('el-table-column',{attrs:{"label":"序号","width":"90"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(scope.$index+1))]}}])}),_c('el-table-column',{attrs:{"label":"专辑封面","width":"130"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_c('el-image',{staticStyle:{"width":"56px","height":"56px"},attrs:{"src":_vm.coverFn(row),"fit":"cover"}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})])])]}}])}),_c('el-table-column',{attrs:{"label":"专辑名称","width":"250","align":"left","prop":"name"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_c('div',{staticStyle:{"overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis"},attrs:{"title":row.album_name}},[_vm._v(" "+_vm._s(row.album_name)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"歌曲数","width":"150","prop":"applys_count"}}),_c('el-table-column',{attrs:{"label":"操作时间"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_c('div',[_vm._v(_vm._s(_vm.moment(row.submit_at).format('YYYY-MM-DD HH:mm')))])]}}])}),_c('el-table-column',{attrs:{"label":"操作人","formatter":_vm.operator}}),_c('el-table-column',{attrs:{"align":"left","width":"120","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function({row}){return [_c('div',{staticClass:"btnsBoxs"},[_c('div',{staticClass:"pointer",on:{"click":function($event){return _vm.updateFn(row)}}},[_vm._v("编辑")]),_vm._v("  "),_c('div',{staticClass:"pointer",on:{"click":function($event){return _vm.deleteFn(row)}}},[_vm._v("删除")])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }