<template>
  <div class="works">
    <LeftMenu current="draftList"></LeftMenu>
    <div class="main" :class="{ draftList: $route.meta.active }">
      <div class="manage">
        <div class="content">
          <div class="backBox">
            <el-breadcrumb separator="/">
            <el-breadcrumb-item to="/applyList">发行管理</el-breadcrumb-item>
            <el-breadcrumb-item>草稿</el-breadcrumb-item>
          </el-breadcrumb>
          <el-link type="primary" @click="$router.back()">返回></el-link>
          </div>
          <el-divider></el-divider>
          <div class="top">
            <span class="title">您有{{draftCount}}个草稿待发布</span>
            <div class="search">
              <el-input
                prefix-icon="el-icon-search"
                v-model="name"
                placeholder="请输入专辑名称或歌曲名称关键字"
                @input="lookupFn"
              >
              </el-input>
            </div>
          </div>
          <draft-list
            :albumList="albumArr"
            @updateFn="updateFn"
            @deleteFn="deleteFn"
            :userList="userList"
          ></draft-list>
          <div class="footer-pagination">
          <el-pagination
            :current-page="paging.cur_page"
            :page-size="paging.size"
            @current-change="currentChange"
            background
            layout="prev, pager, next, slot"
            hide-on-single-page
            :total="paging.total">
          <ul class="alone">
            <li class="totalPage">
              共 {{paging.max_page}} 页
            </li>
            <li>到第
              <el-input v-model.number="inputVal"></el-input>
            </li>
            <li>
              <el-button  @click="butfn()">确定</el-button>
            </li>
          </ul>
        </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="dialogDiv">
      <el-dialog
        :close-on-click-modal="false"
        title="操作确认"
        :visible.sync="popShow"
        height="360px"
        width="480px"
        center
      >
        <div class="popBody">{{popData.title}}</div>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="popShow = false">取 消</el-button>
          <el-button :loading='submitLoading' type="primary" @click="albumDeleteFn" class="determine">确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import DraftList from "./components/applyList/draftList";
import LeftMenu from "../../components/LeftMenu";
import {getDraftCount,getAlbumList,getUserList} from '@/api/issue/'
import {deleteAlbum} from "@/api/issue"
let timer;
const SIZE = 10;
export default {
  components: { DraftList, LeftMenu },
  data() {
    return {
      submitLoading:false,
      popShow:false,
      popData:{
        album_id:'',
        album_name:'',
        title:''
      },
      name: "",
      draftCount:0,
      inputVal:1,
       userList:[],
      paging:{
        total:0,
        page:1,
        size:SIZE
      },
      albumArr: [],
    };
  },
  created() {
     getUserList().then((res) => {
        if (res.code == 0) {
          this.userList = res.data;
        }
      });
    this.getAlbumListFn()
    getDraftCount({},'album','draftCount').then(res=>{
      if(res.code == 0){
        let {draftCount} = res.data
        this.draftCount = draftCount;
      }
    })
  },
  methods: {

    albumDeleteFn(){
      deleteAlbum(this.popData.album_id).then(r => {
        if (r.code === 0) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.popShow = false
          this.getAlbumListFn()
        } else {
          this.$message({
            type: 'error',
            message: r.msg || '删除失败!'
          });
        }
      })
    },

    updateFn(row){
      this.$router.push(`/issue/${row.id}/${row.ver}`)
    },
    deleteFn(row){
      let {album_id,album_name = ''} = row
      let title =''
      title = `请确认是否删除专辑“${album_name}”？`
      this.popData = {
        album_id,
        title,
        album_name
      }
      this.popShow = true
      console.log('删除',row)
    },
    currentChange(val){
      this.paging.page = val
      this.getAlbumListFn()
    },
    butfn(){
      this.paging.page = this.inputVal
      this.getAlbumListFn()
    },
    // 查找
    lookupFn() {
      clearInterval(timer);
      timer = setTimeout(() => {
        this.getAlbumListFn()
      }, 800);
    },
    getAlbumListFn (){
      let p = {
        page:this.paging.page,
        size:SIZE,
        status_ids:[0],
        album_name:this.name
      }
      getAlbumList(p,'album','list').then(res=>{
       if(res.code === 0){
        let { data,total,cur_page,max_page,per_page } = res.data
        this.paging.total = total
        this.paging.cur_page = cur_page
        this.paging.max_page = max_page
        this.paging.per_page = per_page
        this.albumArr = data
       }
      })
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/works";

.draftList {
  padding: 0;
  background-color: #f7f7f7;
}
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

.search {
  ::v-deep .el-input__inner {
    width: 275px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
  }
}

.manage {
  .content {
    box-sizing: border-box;
    background-color: #ffffff;
    width: 100%;
    min-height: 700px;
    padding: 30px 40px;
    .backBox{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      margin-top: 20px;
      .title {
        color: #333333;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}
.dialogDiv{
    .el-button{
      width: 160px;
      height: 40px;
      border-radius: 3px;
    }
    .determine{
      margin-left: 25px;

    }
    ::v-deep .el-dialog{
      border-radius: 10px;
    }
    .popBody{
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      color: #333333;
      font-size: 14px;
    }
   ::v-deep .el-dialog__footer{
      padding:0 0  40px 0;
    }
  }
.footer-pagination {
      margin: 39px 0 39px 0;
      display: flex;
      justify-content: center;

      .alone {
        display: inline-flex;
        height: 30px;
        align-items: center;
        font-size: 12px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #555555;

        .totalPage {
          width: 40px;
          margin: 0 17px 0 6px;
        }

        ::v-deep .el-input {
          .el-input__inner {
            width: 30px;
            height: 30px;
            border: 1px solid #e1e0e0;
            border-radius: 2px;
          }
        }
        .el-button{
          margin-left:6px;
          width: 48px;
          height: 30px;
          background: #ffffff;
          border: 1px solid #e1e0e0;
          border-radius: 2px;
        }
      }

      .alone > li {
        display: inline-block;
      }
    }
</style>
