<template>
  <el-table
      :data="tableData"
      class="table"
      ref="table"
      :header-cell-style="{
        background:'#f7f7f7',
        fontSize: '14px',
        fontFamily:'PingFang SC-Regular',
        fontWeight: 400,
        color: '#333333',
      }"
      style="width: 100%">
    <el-table-column label="序号" width="90" >
      <template slot-scope="scope">{{scope.$index+1}}</template>
    </el-table-column>

    <el-table-column label="专辑封面" width="130" v-slot="{row}" >
      <el-image style="width: 56px; height: 56px" :src="coverFn(row)" fit="cover">
        <div slot="error" class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </el-image>
    </el-table-column>

    <el-table-column label="专辑名称" width="250" align="left" prop="name" v-slot="{row}" >
      <div style="overflow: hidden;white-space: nowrap;text-overflow: ellipsis;" :title="row.album_name">
        {{ row.album_name }}
      </div>
    </el-table-column>

    <el-table-column label="歌曲数" width="150" prop="applys_count" />

    <el-table-column label="操作时间"  v-slot="{row}">
      <div>{{ moment(row.submit_at).format('YYYY-MM-DD HH:mm')}}</div>
    </el-table-column>

    <el-table-column label="操作人"  :formatter="operator" />


    <el-table-column
        align="left"
        v-slot="{row}"
        width="120"
        label="操作"
        >
      <div class="btnsBoxs">
        <div @click="updateFn(row)" class="pointer">编辑</div>&nbsp;
        <div @click="deleteFn(row)" class="pointer">删除</div>
      </div>
    </el-table-column>
  </el-table>
</template>

<script>
import {delAlbum} from "@/api/song"
import moment from "moment"
export default {
  props:{
    albumList: Array,
    userList: Array
  },
  name: "albumList",
  data(){
    return {
      tableData: [],
      moment
    }
  },


  methods:{
    coverFn(row){
      let {cover} = row
      let url = ''
      if(cover){
        url = cover.location
      }
      return url
    },

    deleteAlbum(id){
      delAlbum({}, {albumId: id}).then(() => {
        this.$parent.albumListFn()
        this.$refs['popover-' + id].showPopper = false
      })
    },
    operator(val){
      let name = '--'
      this.userList.forEach(item=>{
        if(item.id == val.operator){
          name = item.nickName
        }
      })
      return name
    },

    updateFn(row){
      this.$emit('updateFn',row)
    },
    deleteFn(row){
      this.$emit('deleteFn',row)
    },

  },
  mounted() {
    this.tableData = this.albumList
  },
  watch:{
    albumList(val){
    this.tableData = val
    }
  }
}
</script>

<style scoped lang="scss">
.hideExpend{
  display: none;
}
</style>
<style>
/* .expand .el-table__expand-column {
    display: none;
} */
.el-table__expand-column .cell {
  display: none;
}
.image-slot{
  width: 56px;
  height: 56px;
  background: #f5f7fa;
  font-size: 40px;
  color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btnsBoxs{

  display: flex;
  align-items: center;
}
.pointer{
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FF477E;
  font-size: 14px;
}
.doBoxs{
  display: flex;
  /* align-items: center; */
  justify-content: space-around;
}
.items{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .items span{
    color: #999;
    font-size: 12px;
    padding-bottom: 11px;
  }
  .items i{
    color: #666;
    font-size: 12px;
    font-style: normal;
  }
  .dd{
    display: flex;
  }
  .iconBox{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon{
    font-size: 16px;
    cursor: pointer;
    margin-left: 6px;
  }
</style>
